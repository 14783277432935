<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="dataConfig && dataLabels" />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
