const base = process.env.VUE_APP_MHR_API_URL

const endpoints = {
  // GENERAL
  getLabels: base + 'label',
  getConfig: base + 'config',
  // HOME
  getHome: base + 'homepage',
  // ABOUT US
  getAbout: base + 'about',
  // ALL BRANDS
  getBrands: base + 'brands',
  // DINING
  getDining: base + 'dining',
  getDiningMoreOffers: base + 'load-more-fb-offer',
  // EVENTS
  getEvents: base + 'events',
  getEventsMoreVenues: base + 'load-more-venue',
  // FEATURES
  getFeatures: base + 'features',
  getFeaturesMorePress: base + 'load-more-press',
  getFeaturesMoreBlogs: base + 'load-more-blog',
  // FEATURES DETAIL
  getPressDetail: base + 'press-detail',
  getBlogDetail: base + 'blog-detail',
  // CLUB ACCESS
  getClubAccess: base + 'club-access',
  // PROMOTIONS
  getPromotionsHotels: base + 'list-hotels-has-promotion',
  getPromotions: base + 'promotion',
  getPromotionsOffers: base + 'list-promotion',
  getMorePromotions: base + 'load-more-promotion',
  // PROMOTIONS DETAIL
  getPromotionDetail: base + 'offer-detail',
  getVenueDetail: base + 'venue-detail',
  getEventDetail: base + 'event-detail',
  // CAREERS
  getCareers: base + 'careers',
  // DATA PRIVACY
  getPrivacy: base + 'privacy',
  // FOOTER
  postSubscriber: base + 'submit-subscriber'
}

const headers = {
  Authorization: 'Bearer ' + process.env.VUE_APP_MHR_API_TOKEN
}

// GENERAL
export async function getLabels () {
  const res = await fetch(endpoints.getLabels, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getConfig () {
  const res = await fetch(endpoints.getConfig, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// HOME
export async function getHome () {
  const res = await fetch(endpoints.getHome, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// ABOUT US
export async function getAbout () {
  const res = await fetch(endpoints.getAbout, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// ALL BRANDS
export async function getBrands () {
  const res = await fetch(endpoints.getBrands, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// DINING
export async function getDining () {
  const res = await fetch(endpoints.getDining, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getDiningMoreOffers (amount, index) {
  const res = await fetch([endpoints.getDiningMoreOffers, amount, index].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// EVENTS
export async function getEvents () {
  const res = await fetch(endpoints.getEvents, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getEventsMoreVenues (amount, index) {
  const res = await fetch([endpoints.getEventsMoreVenues, amount, index].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// FEATURES
export async function getFeatures () {
  const res = await fetch(endpoints.getFeatures, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getFeaturesMorePress (amount, index) {
  const res = await fetch([endpoints.getFeaturesMorePress, amount, index].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getFeaturesMoreBlogs (amount, index) {
  const res = await fetch([endpoints.getFeaturesMoreBlogs, amount, index].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// FEATURES DETAIL
export async function getPressDetail (id) {
  const res = await fetch([endpoints.getPressDetail, id].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getBlogDetail (id) {
  const res = await fetch([endpoints.getBlogDetail, id].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// CLUB ACCESS
export async function getClubAccess () {
  const res = await fetch(endpoints.getClubAccess, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// PROMOTIONS
export async function getPromotionsHotels () {
  const res = await fetch(endpoints.getPromotionsHotels, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getPromotions () {
  const res = await fetch(endpoints.getPromotions, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getPromotionsOffers (category, hotel_id) {
  const res = await fetch([endpoints.getPromotionsOffers, category || 'all', hotel_id || 'all'].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getMorePromotions (amount, index, category, hotel_id) {
  const res = await fetch([endpoints.getMorePromotions, amount, index, category || 'all', hotel_id || 'all'].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// PROMOTIONS DETAIL
export async function getPromotionDetail (id) {
  const res = await fetch([endpoints.getPromotionDetail, id].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getVenueDetail (id) {
  const res = await fetch([endpoints.getVenueDetail, id].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
export async function getEventDetail (id) {
  const res = await fetch([endpoints.getEventDetail, id].join('/'), { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// CAREERS
export async function getCareers () {
  const res = await fetch(endpoints.getCareers, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// DATA PRIVACY
export async function getPrivacy () {
  const res = await fetch(endpoints.getPrivacy, { headers })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
// FOOTER
export async function postSubscriber (email) {
  const res = await fetch(endpoints.postSubscriber, {
    method: 'POST',
    headers,
    body: JSON.stringify({ email })
  })
  if (!res.ok) throw new Error(res.status)
  return res.json()
}
