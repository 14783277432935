// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    // MHR Routes
    {
      path: '/',
      component: () => import('@/layouts/mhr/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/mhr/Home.vue')
        },
        {
          path: '404',
          name: 'Error Not Found',
          component: () => import('@/views/mhr/ErrorNotFound.vue')
        },
        {
          path: 'about-us',
          name: 'About Us',
          component: () => import('@/views/mhr/AboutUs.vue')
        },
        {
          path: 'our-brands',
          name: 'Our Brands',
          component: () => import('@/views/mhr/OurBrands.vue')
        },
        {
          path: 'dining',
          name: 'Dining',
          component: () => import('@/views/mhr/Dining.vue')
        },
        {
          path: 'events',
          name: 'Events',
          component: () => import('@/views/mhr/Events.vue')
        },
        {
          path: 'events/:promoId/:promoName?',
          name: 'Events Detail',
          props: route => ({
            promoType: 'event',
            promoId: route.params.promoId
          }),
          component: () => import('@/views/mhr/PromotionsDetail.vue'),
          meta: route => ({ gtm: `Events Detail: ${route.params.promoName}` })
        },
        {
          path: 'venues/:promoId/:promoName?',
          name: 'Venues Detail',
          props: route => ({
            promoType: 'venue',
            promoId: route.params.promoId
          }),
          component: () => import('@/views/mhr/PromotionsDetail.vue'),
          meta: route => ({ gtm: `Venues Detail: ${route.params.promoName}` })
        },
        {
          path: 'features',
          name: 'Features',
          component: () => import('@/views/mhr/Features.vue')
        },
        {
          path: 'features/press/:featureId/:featureName?',
          name: 'Features Press Detail',
          props: route => ({
            featureType: 'press',
            featureId: route.params.featureId
          }),
          component: () => import('@/views/mhr/FeaturesDetail.vue'),
          meta: route => ({ gtm: `Features Press Detail: ${route.params.featureName}` })
        },
        {
          path: 'features/blog/:featureId/:featureName?',
          name: 'Features Blog Detail',
          props: route => ({
            featureType: 'blog',
            featureId: route.params.featureId
          }),
          component: () => import('@/views/mhr/FeaturesDetail.vue'),
          meta: route => ({ gtm: `Features Blog Detail: ${route.params.featureName}` })
        },
        {
          path: 'club-access',
          name: 'Club Access',
          component: () => import('@/views/mhr/ClubAccess.vue')
        },
        {
          path: 'careers',
          name: 'Careers',
          component: () => import('@/views/mhr/Careers.vue')
        },
        {
          path: 'promotions',
          name: 'Promotions',
          component: () => import('@/views/mhr/Promotions.vue')
        },
        {
          path: 'promotions/room/:promoId/:promoName?',
          name: 'Promotions Room Detail',
          props: route => ({
            promoType: 'room',
            promoId: route.params.promoId
          }),
          component: () => import('@/views/mhr/PromotionsDetail.vue'),
          meta: route => ({ gtm: `Promotions Room Detail: ${route.params.promoName}` })
        },
        {
          path: 'promotions/fnb/:promoId/:promoName?',
          name: 'Promotions F&B Detail',
          props: route => ({
            promoType: 'fnb',
            promoId: route.params.promoId
          }),
          component: () => import('@/views/mhr/PromotionsDetail.vue'),
          meta: route => ({ gtm: `Promotions F&B Detail: ${route.params.promoName}` })
        },
        {
          path: 'data-privacy',
          name: 'Data Privacy',
          component: () => import('@/views/mhr/DataPrivacy.vue')
        }
      ]
    },

    // Savoy Brand Page Route
    {
      path: '/savoyhotels',
      component: () => import('@/layouts/savoy/Index.vue'),
      children: [
        {
          path: '',
          name: 'Savoy',
          component: () => import('@/views/savoy/Index.vue')
        }
      ]
    },

    // Belmont Brand Page Route
    {
      path: '/belmonthotels',
      component: () => import('@/layouts/belmont/Index.vue'),
      children: [
        {
          path: '',
          name: 'Belmont',
          component: () => import('@/views/belmont/Index.vue')
        }
      ]
    },

    // Belmont Brand Page Route
    {
      path: '/richmondehotels',
      component: () => import('@/layouts/richmonde/Index.vue'),
      children: [
        {
          path: '',
          name: 'Richmonde',
          component: () => import('@/views/richmonde/Index.vue')
        }
      ]
    },

    // Boracay Newcoast Campaign
    {
      path: '/boracay-newcoast',
      component: () => import('@/layouts/bnc/Index.vue'),
      children: [
        {
          path: '',
          name: 'Boracay Newcoast',
          component: () => import('@/views/bnc/Index.vue'),
          meta: { gtm: `Boracay Newcoast Campaign` }
        }
      ]
    },

    // 404 Route
    {
      path: '*',
      redirect: '/404'
    }
  ]
})

const brands = ['Belmont', 'Savoy', 'Richmonde', 'Boracay Newcoast']

router.beforeEach((to, from, next) => {
  // Reload styles if from brand page to elsewhere
  brands.includes(from.name) && from.name !== to.name
  ? window.location.href = to.path
  : next()
})

export default router
