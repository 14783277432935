var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-card",class:{
    [_vm.borderColor]: _vm.borderColor
  },style:({
    'width': _vm.width,
    'height': _vm.height,
    borderRadius: [_vm.borderRadius],
    borderWidth: [_vm.borderWidth]
  })},[_c('img',{staticClass:"image-card__mask",attrs:{"src":_vm.image,"alt":""}}),_c('div',{staticClass:"image-card__content text-center text-lg-left w-100"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }