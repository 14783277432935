<template>
  <div class="number-text-field">
    <label class="number-text-field__label" v-html="label" />
    <div class="number-text-field__controls">
      <button class="number-text-field__btn number-text-field__decrement" @click.prevent="decrement">
        <v-icon class="brand-primary--text">fa-solid fa-minus</v-icon>
      </button>
      <input v-model="inputValue" class="number-text-field__input" type="text" @keypress="isNumber($event)">
      <button class="number-text-field__btn number-text-field__increment" @click.prevent="increment">
        <v-icon class="brand-primary--text">fa-solid fa-plus</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NumberTextField',

    props: {
      value: {
        type: Number,
        default: 0
      },
      label: {
        type: String,
        default: ''
      }
    },

    data () {
      return {
        inputValue: this.value
      }
    },

    watch: {
      value (val) {
        this.inputValue = val
      },
      inputValue (val) {
        const output = Number(val) === 0 ? 1 : Number(val)
        this.inputValue = output
        this.$emit('input', output)
      }
    },

    methods: {
      increment () {
        this.inputValue++
      },
      decrement () {
        if (this.inputValue > 1) {
          this.inputValue--
        }
      },
      isNumber: function (evt) {
        evt = (evt) || window.event
        const charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.number-text-field {
  display: flex;
  justify-content: space-between;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  align-items: center;

  &__controls {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: flex;
    align-items: center;
  }

  &__input {
    text-align: center;
  }
}
</style>
